import React, { useMemo, useState } from 'react';
import { Box, Fab, SxProps, Theme, useTheme } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

export interface InlineYoutubePlayerProps {
  youtubeId: string;
  thumbnail?: string;
  sx?: SxProps<Theme> | undefined;
}

const InlineYoutubePlayer = ({
  youtubeId,
  thumbnail,
  sx
}: InlineYoutubePlayerProps) => {
  const theme = useTheme();
  const [hasPlayClicked, setPlayClicked] = useState(false);
  const [hasPlayHovered, setPlayHovered] = useState(false);

  const src = useMemo(() => {
    const url = new URL(`https://www.youtube.com/embed/${youtubeId}`);

    if (hasPlayClicked) {
      url.searchParams.set('autoplay', '1');
    }
    return url.toString();
  }, [hasPlayClicked, youtubeId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: theme.spacing(38),
        [theme.breakpoints.up('sm')]: {
          alignItems: 'center',
          height: theme.spacing(75)
        },
        ...(sx || {})
      }}
    >
      <Box width={760} maxWidth={'100%'} overflow={'hidden'}>
        <Box
          width={'100%'}
          position={'relative'}
          paddingBottom={'56.25%'}
          onMouseEnter={() => setPlayHovered(true)}
        >
          {hasPlayHovered && (
            <Box
              component={'iframe'}
              sx={{
                opacity: hasPlayClicked ? 1 : 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%'
              }}
              src={src}
              title={'YouTube Video'}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen={true}
            />
          )}
          {!hasPlayClicked && (
            <>
              <Box
                component={'img'}
                width={'100%'}
                alt={'YouTube Video Thumbnail'}
                position={'absolute'}
                top={'50%'}
                sx={{ transform: 'translateY(-50%)' }}
                src={
                  thumbnail ||
                  `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`
                }
              />
              <Fab
                color={'primary'}
                size={'large'}
                aria-label={'Youtube Play Button'}
                onClick={() => setPlayClicked(true)}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <PlayArrow fontSize={'large'} />
              </Fab>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

InlineYoutubePlayer.strapiComponent = 'maastr.inline-youtube-player';

export { InlineYoutubePlayer };
